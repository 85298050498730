@import '../variables';
@import '../mixin';

.page-container.checkout {
    .checkout-form {
        .checkout-details {
            .main {
                .log-in {
                    margin-bottom: 15px;
                    margin-top: 36px;
                    font-size: 13px;
                    text-align: center;

                    a {
                        font-size: inherit;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .checkout {
        .head {
            div {
                span {
                    color: $color-green-2;
                }
            }
        }
    }

    .payment_select {
        background-color: #f0f0f0;
        border: 3px solid #f0f0f0;
        padding: 15px 15px 10px 15px;
        margin-bottom: 20px;
        @include border-radius(3px);
        cursor: pointer;

        input {
            display: none;
        }

        label {
            font-size: 16px;
            display: inline-block;
            margin-bottom: 0;
        }

        i {
            float: right;
            font-size: 24px;
        }

        span {
            float: right;
        }

        &#paypal {
            background: #f0f0f0 url(../../img/paypal_2.svg) center right no-repeat;
        }

        &.glovo {
            img {
                position: relative;
                top: -3px;
            }
        }

        &.disabled-input {
            background-color: #d7d7d7 !important;
            border-color: #d7d7d7;
            cursor: not-allowed !important;
        }

        .container_left {
            float: right;
        }
    }
}

#terms-consent {
    margin-bottom: 2px;
}

.terms-consent-notice {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    & > a {
        font-weight: bold;
    }
}

.preparation-time {
    text-align: center;
    font-size: 0.95em;

    span {
        color: $color-1;
        font-weight: bold;
    }
}

.checkout-phone-input {
    padding-left: 45px;
}

.checkout-phone-flag {
    width: 27px;
    position: absolute;
    left: 25px;
    bottom: -36px;
    @include transform (translate(-50%, -50%));
}

.checkout-error {
    .error-message {
        background-color: rgba(229,14,57, 0.15);
        padding: 6px 12px;
        margin: 16px;
        border-radius: 4px;;
        border: 2px solid $color-red-light;
    }
}